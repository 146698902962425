<template>
    <div class="d-flex justify-content-between px-0 flex-wrap">
        <div class="d-flex align-items-start align-items-md-center bg-white w-100 px-0" :class="{'px-lg-1': viewPort('lg')}" style="height: 50px;">
            <router-link tag="div" to="/account/wishlist" class="arrow-container flex-shrink-0" style="margin-right: 10px;">
                <icon-arrow :size="25" :color="blue ? 'var(--blue-600)':'var(--dark-gray-200)'" style="transform: rotate(180deg) translate(4px, 1px);" class="hover-blue-600"/>
            </router-link>                                                                         <!-- This messy structure is needed because of the strange text-limited and div in the same line case -->
            <div class="title-description d-flex align-items-center flex-wrap w-100" style="margin-left: 7px;"> <!-- that also needs to be limited by the bootstrap column. -->
                <div class="list-name d-flex">
                    <router-link tag="h6" to="/account/wishlist" class="d-inline text-limited size-22 mb-0 cursor-pointer" @mouseover="blue = true" @mouseleave="blue = false">
                        {{ listSelected.name }}
                    </router-link>
                </div>
                <div class="d-flex position-relative">
                    <icon-edit @click.native="editWishList = true" :size="19" class="flex-shrink-0 ms-2 ms-lg-3 cursor-pointer" :color="editWishList ? 'var(--blue-600)':'var(--gray-1001)'" />
                    <half-circle-spinner v-if="editingList.value" :animation-duration="1000" :size="20" color="var(--main-red)" class="ms-3" />
                    <edit-wish-list-pop-up v-if="editWishList && viewPort('lg')" :listSelected="this.listSelected" @hideEdit="editWishList = false" style="top: 36px; left: -20px;"/>
                    <edit-wishlist v-else-if="editWishList" @closeModal="editWishList = false" :listSelected="this.listSelected" />
                </div>
                <div class="d-flex d-lg-none ms-auto" icon id="create-cart" title="wishlist.create_cart">
                    <icon-cart @click.native.prevent="$emit('createCart')" :size="24" color="var(--gray-1001)" class="cursor-pointer ms-auto me-2" />
                    <div class="m-0 p-0" :class="{'deactivate-icon': disableDelete}">
                        <icon-trash @click.native.prevent="$emit('deleteProduct')" :size="24" :color="disableDelete ? 'var(--light-gray-501)':'var(--gray-1001)'" class="cursor-pointer" :class="{'pointer-events-none': disableDelete}" />
                    </div>
                </div>
                <div class="d-none d-lg-flex ms-auto">
                    <second-button icon class="px-3" title="cart.delete_selection" @click="$emit('deleteProduct')" :disabled="disableDelete">
                        <icon-trash :size="22" color="var(--dark-main-navy)" />
                    </second-button>
                    <!-- Only available on desktop-->
                    <main-button icon class="ms-3" id="create-cart" title="wishlist.create_cart">
                        <icon-cart class="me-2" :size="17" color="#fff" />
                    </main-button>
                    <!---->
                </div>
                <div class="d-flex align-items-center" style="margin-top: 2px; height: 21px; width: 100%;">
                    <span class="title-14 mb-0 me-2">{{$t("wishlist.public")}}</span>
                    <main-switch id="select-public" class="select-public-details me-3" />
                    <span class="d-none d-md-flex subtitle-13 color-dark-gray-200 weight-400">{{listSelected.products.length}} {{$t("wishlist.saved_products")}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SecondButton from '../../../../common/components/buttons/SecondButton.vue' 
import MainSwitch from "../../../../common/components/switch/MainSwitch"
import MainButton from '../../../../common/components/buttons/MainButton.vue'

import IconArrow from '../../../../common/svg/iconArrow'
import IconEdit from '../../../checkout/assets/svg/iconEdit'
import IconTrash from '../../../../common/svg/iconTrash.vue'
import IconCart from '../../../cart/assets/svg/iconCart.vue'
import EditWishListPopUp from '../EditWishListPopUp.vue'
import EditWishlist from '../modals/EditWishlist.vue'

import { HalfCircleSpinner } from 'epic-spinners'
import { mapGetters } from 'vuex'

export default {
    name: 'WishListHeader',
    components: { HalfCircleSpinner, MainButton, MainSwitch, IconEdit, IconArrow, IconCart, IconTrash, SecondButton, EditWishListPopUp, EditWishlist },
    props: {
        listSelected: {
            type: Object,
            required: true
        },
        selectAll: {
            type: Boolean
        },
        disableDelete: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters({
            editingList: "wishlist/editingList"
        })
    },
    data() {
        return {
            blue: false,
            timer: null,
            name: '',
            editWishList: false,
        }
    },
}

</script>

<style scoped>
    .button-secondary::v-deep > span > div {
        margin-right: 0.5rem;
    }    
    .button-secondary {
        padding-inline: 1.4rem;
    }
    svg.icon-edit { transition: var(--transition-1) }
    svg.icon-edit:hover { fill: var(--blue-600) }
    .popup::v-deep::before { /* aligning our pointy thingy */
        top: 0;
        left: 9%;
        transform: translate(50%, -50%) rotate(314deg);
    }
    .list-name {
        max-width: 190px;
    }
    .list-name h6 {
        transition: var(--transition-1);
    }
    .create-cart:hover span {
        color: var(--main-red) !important;
        text-decoration: underline;
    }
    .create-cart svg { transition: var(--transition-1); }
    .create-cart:hover svg{ fill: var(--main-red) !important; }
    .arrow-container {
        margin-top: 8px;
    }
    .unstyled-button svg:hover {
        fill: var(--gray-1001) !important;
    }
    .deactivate-icon {
        cursor: no-drop !important;
    }
    @media (min-width: 767px) {
        .clear-all > svg {
            position: absolute;
            top: 2px;
            width: 18px !important;
            opacity: 0;
        }
    }
    @media (min-width: 992px) {
        .list-name {
            max-width: 500px;
        }
        .arrow-container {
            margin-top: 0;
        }
    }
    @media (max-width: 991px) {
        .icon-trash, .icon-cart{ transition: var(--transition-1); }
        .icon-trash:hover, .icon-cart:hover { fill: var(--main-red) !important; }
    }
</style>