<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" :confirm-title="confirmTitle" 
        :description="description">
        <icon-delete :size="100"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconDelete from '../../../cart/assets/svg/iconDelete.vue'
export default {
    components: { MainConfirm, IconDelete },
    name: "DeleteProduct",
    props: {
        confirmTitle: {
            type: String,
        },
        description: {
            type: String,
        }
    }
}
</script>