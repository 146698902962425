<template>
    <div class="default-color d-inline-block me-2">
        <div class="color-outline d-flex align-content-center align-items-center justify-content-center" :style="{background: getColor(selected)+'33'}">
            <div class="color d-flex align-content-center align-items-center justify-content-center" :style="{background: getColor(selected)}">
                <icon-check class="check-icon" color="#fff" :size="10" />
            </div>
        </div>
    </div>
</template>

<script>
import iconCheck from "@/common/svg/iconCheck.vue"
export default {
    name: 'DefaultColor',
    components:{
        iconCheck
    },
    data() {
        return {
            selected: null,
        }
    },
    props: {
        selectedColor: {
            type: String,
            default: 'black'
        }

    },
    methods: {
        //translating the color (could be improved by using $i18n)
        translateColor(color){ 
            let tcolor = color.toLowerCase();
            switch (tcolor) {
                case 'negro':
                    return 'black';
                case 'blanco':
                    return 'white';
                case 'plateado':
                    return 'gray';
                case 'plata':
                    return 'gray';
                case 'verde':
                    return 'green';
                case 'rosado':
                    return 'pink';
                case 'rojo':
                    return 'red';
                case 'azul':
                    return 'blue';
                default:
                    return tcolor;
            }
        },
        getColor(color){ // setting the hex value
            switch (color) {
                case 'black':
                    return '#212E3D'
                case 'white':
                    return '#ffffff'
                case 'red':
                    return 'var(--main-red)'
                case 'yellow':
                    return '#F9B530'
                case 'blue':
                    return '#4980D5'
                case 'gray':
                    return '#929191'
                default:
                    break;
            }
        },
    },
    created() {
        //translating the colors
        this.selected = this.translateColor(this.selectedColor)
    }
}
</script>

<style scoped>
.color, .color-outline {
    border-radius: 3.12rem;
}
.color-outline {
    width: 24px;
    height: 24px;
    
    transform: scale(0.98);
}
.color-outline:not(:last-child) {
    margin-right: 14px;
}
.color {
    width: 16px;
    height: 16px;
}
.check-icon{
    animation: appear 0.15s;
}
@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    70% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>