<template>
    <div class="col-12 px-0">
        <div v-if="!loading_wishlist" class="row mx-0">
            <wish-list-header :selectAll="selectedAll" :disableDelete="selectedProducts.length < 1" :listSelected="wishlist" 
            @removeData="$emit('removeData')" @deleteProduct="showDeleteProduct = true;" />
            <div v-if="wishlist.note" class="col-md-12 mt-3"> 
                <span class="wishlist-description d-flex color-dark-gray-200 weight-300" style="margin-left: 30px;">
                    {{ wishlist.note }}
                </span>
            </div>
            <div class="animation-slide-down col-md-12" :class="[wishlist.note ? 'mt-3':'mt-2 mt-md-4', {'px-0': !desktopViewPort }]">
                <table class="table mx-auto">
                    <thead style="border-bottom: 2px solid #a5b2b717 !important">
                        <tr>
                            <template v-if="desktopViewPort">
                                <th class="d-flex align-items-center weight-600"> 
                                    <main-checkbox class="ms-2 me-3" id="select-all" :value="selectedAll" @change="selectAll"/> {{$t("wishlist.product")}}
                                </th>
                                <th class="text-center weight-600">{{$t("wishlist.action")}}</th>
                                <th class="text-center weight-600">{{$t("wishlist.subtotal")}}</th>
                            </template>
                            <template v-else>
                                <th class="d-flex align-items-center weight-600 px-0"> 
                                    <main-checkbox class="ms-0 me-3" id="select-all" :value="selectedAll" @change="selectAll"/> {{$t("wishlist.product")}}
                                    <span class="ms-auto subtitle-14 color-dark-gray-200 weight-400 me-2">{{wishlist.products.length}} {{$t("wishlist.saved_products")}}</span>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in wishlist.products" :key="product._id">
                            <template v-if="desktopViewPort">
                                <td class="align-middle">
                                    <div class="d-flex">
                                        <main-checkbox isList :id="'select-product-'+product.product.codarticulo" :values="selectedProducts" class="ms-2 me-3" :value="product.code"
                                            @change="selectAProduct(product)" />
                                        <product-short-details wishlist :product="product.product" @showQuickView="showQuick" :custom="{width: '100px', height: '100px'}" />
                                    </div>
                                </td>
                                <td class="align-middle text-center responsive-column">
                                    <div class="d-flex justify-content-around mx-auto" style="max-width: 170px;">
                                        <icon-trash :size="28" @click.native="deleteProduct(product)" color="var(--gray-1001)" class="hover-main-red" />
                                        <icon-cart :size="28" @click.native="increment(product.product)" color="var(--gray-1001)" class="hover-main-red" :class="[{'btn-disabled': getQuantity(product) >= product.product.existence}]"/>
                                        <icon-share :size="23" @click.native="showShare(product)" color="var(--gray-1001)" class="hover-main-red" />
                                    </div>
                                </td>
                                <td class="align-middle title-14 weight-700 text-center responsive-column">
                                    {{product.product.price | moneyFormat}}
                                    <b class="color-light-main-red weight-700"></b>
                                </td>    
                            </template>
                            <template v-else>
                                <td class="d-flex w-100 px-0 py-3">
                                    <main-checkbox isList :id="'select-product-'+product.product.codarticulo" :values="selectedProducts" class="ms-0" style="margin-right: 13px;" :value="product.code"
                                    @change="selectAProduct(product)" />
                                    <div class="product-information d-flex w-100">
                                        <router-link :to="{ name: 'product-detail', params: { id: product._id }}">
                                            <product-image class="img-product-wishlist me-3 flex-grow-0" :styleObject="{width: '100px', height: '100px', objectFit: 'contain'}" :source="getImages(product.product.imageGallery)" />
                                        </router-link>
                                        <div class="d-flex flex-column flex-grow-1">
                                            <div class="d-flex">
                                                <span class="product-description d-block text-limited" data-toggle="tooltip" data-placement="top" :title="product.product.description | textFormat">{{ product.product.description | textFormat }}</span>
                                                <icon-trash @click.native="deleteProduct(product)" class="ms-auto" :size="24" color="var(--gray-1001)" />
                                            </div>
                                            <star-rating :rating="product.product.avgRating" readOnly :showRating="false" :starSize="18" class="mb-0" style="margin-top: -3px;" />
                                            <div style="margin-top: 2.5px; min-width: 200px;">
                                                <default-color />
                                                <span class="subtitle-13 me-3">
                                                    {{$t("product.color")}}: 
                                                    <b class="color-dark-gray-300 weight-500 ms-1">{{product.color ? (product.color) : 'N/a' | textFormat}}</b>
                                                </span>
                                                <span class="subtitle-13">
                                                    {{$t("product.brand")}}: 
                                                    <b class="color-dark-gray-300 weight-500 ms-1">{{product.brand ? (product.brand) : 'N/a' | textFormat}}</b>
                                                </span>    
                                            </div>
                                            <div class="d-flex mt-auto">
                                                <icon-cart class="me-2" :size="22" color="var(--gray-1001)" />
                                                <icon-share @click.native="showShare(product)" :size="19" color="var(--gray-1001)" />
                                                <span class="size-15 ms-auto color-dark-main-navy weight-500">{{product.product.price | moneyFormat}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>    
            </div>
        </div>
        <!-- Loading SPINNER -->
        <div v-else class="row align-content-start">           
            <div class="col-12">
                <div class="row justify-content-between">
                    <div class="col-9 col-md-3">
                        <skeleton :width="100" :height="60" percentage />
                    </div>
                    <div class="col-3">
                        <skeleton class="mt-2" :width="100" :height="45" percentage />
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-5 mb-1">
                <div class="row justify-content-between align-items-end">
                    <div class="col-7 col-md-3 col-lg-2">
                        <skeleton class="mt-2" :width="100" :height="45" percentage />
                    </div>
                    <div class="col-4 col-md-3 col-lg-2">
                        <skeleton class="mt-2" :width="100" :height="35" percentage />
                    </div>
                    <div class="d-none d-lg-block col-md-3 col-lg-2">
                        <skeleton class="mt-2" :width="100" :height="45" percentage />
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3 mt-md-0">
                <skeleton class="mt-md-4" :width="100" :height="140" percentage />
                <skeleton class="mt-2 mt-md-4" :width="100" :height="140" percentage />
                <skeleton class="mt-2 mt-md-4" :width="100" :height="140" percentage />
                <skeleton class="mt-2 mt-md-4" :width="100" :height="140" percentage />
            </div>
        </div>
        <share v-if="share.value" @closeModal="share.value = false" :product="share.product" />
        <quick-view v-if="quick.value" :product="quick.product" @closeModal="quick.value = false" />
        <delete-product v-if="showDeleteProduct" @closeModal="cancel" @confirmDelete="confirmDeleteProduct" :confirmTitle="deleteLabels.title" :description="deleteLabels.description" />
    </div>
</template>

<script>
import { productMixin } from "../../../products/helpers/mixin/product-mixin";
import { mapGetters } from "vuex"

import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue'
import ProductShortDetails from '../../../cart/components/ProductShortDetails.vue'
import DeleteProduct from '../modals/DeleteProduct.vue'
import ProductImage from '../../../products/components/Image.vue'
import QuickView from '../../../products/components/quick-view/QuickView.vue'
import Share from '../../../products/components/modals/Share.vue'
import WishListHeader from './WishListHeader.vue'
import StarRating from '../../../products/components/rating/StarRating'
import Skeleton from '../../../../common/components/skeleton/Skeleton'

import DefaultColor from '../../../products/components/DefaultColor';
// Icons
import IconShare from '../../../../common/svg/iconShare.vue'
import IconTrash from '../../../../common/svg/iconTrash.vue'
import IconCart from '../../../cart/assets/svg/iconCart.vue'

export default {
    components: { IconCart, IconTrash, IconShare, ProductImage, DefaultColor, MainCheckbox, ProductShortDetails, DeleteProduct, QuickView, Share, WishListHeader, StarRating, Skeleton },
    name: "WishListDetails",
    mixins: [productMixin],
    computed: {
        ...mapGetters({
            lists: "wishlist/lists",
        })
    },
    data() {
        return {
            desktopViewPort: false,
            showDeleteProduct: false,
            deleteLabels: { title: 'wishlist.delete_products_modal.title_plural', description: 'wishlist.delete_products_modal.description_plural' },
            selectProduct: {},
            selectedProducts: [],
            selectedAll: false,
            quick: { value: false, product: null },
            share: { value: false, product: null },
            listenerTimeout: null,
            wishlist: null,
            loading_wishlist: true,
        }
    },
    watch: {
        selectedProducts(val){
            this.selectedAll = val.length == this.wishlist.products.length;
            const mode = val.length > 1 ? 'plural':'singular';
            this.deleteLabels.title = 'wishlist.delete_products_modal.title_'+mode;
            this.deleteLabels.description = 'wishlist.delete_products_modal.description_'+mode;
        },
        lists(val){ //if the wishlists 
            this.wishlist = val[val.findIndex(({_id}) => _id === this.$route.params.wishlist_id)];
            this.loading_wishlist = false;
        }
    },
    methods: {
        selectAll() {
            this.selectedAll = !this.selectedAll;
            if (this.selectedAll) {
                this.wishlist.products.map((product) => {
                    if (this.selectedProducts.findIndex((_list) => _list === product.code) === -1 ) this.selectedProducts.push(product.code); //only add it they're not already there
                });
            }else {
                this.selectedProducts = [];
            }
        },
        deleteProduct(product) {
            this.selectedProducts = []
            this.selectedProducts.push(product.code);
            this.showDeleteProduct = true;
        },
        async confirmDeleteProduct() {
            try {
                if (this.selectedProducts.length === this.wishlist.products.length) {
                    await this.$store.dispatch('wishlist/destroyList', this.wishlist._id);
                    this.$router.replace('/account/wishlist');
                }else {
                    this.selectedProducts.map(async (product_code) => {
                        await this.$store.dispatch("wishlist/removeProductFromList",  { list_id: this.wishlist._id, product_code });
                    });
                }
                this.selectedProducts = [];
            } catch (error) {
                console.log(error);
            }finally {
                this.showDeleteProduct = false;
            }
        },
        selectAProduct(product){
            const index = this.selectedProducts.findIndex(product_ => product_ === product.code);
            if(index >= 0) {
                this.selectedProducts.splice(index, 1);
            }else {
                this.selectedProducts.push(product.code);
            }
        },
        cancel(){
            this.selectedProducts = []
            this.showDeleteProduct = false
        },
        showQuick(product){
            this.quick.product = product
            this.quick.value = true
        },
        showShare(product){
            this.share.value = true
            this.share.product = product
        },
    },
    created() { //if we got the wishlists loaded right away
        this.wishlist = this.lists[this.lists.findIndex(({_id}) => _id === this.$route.params.wishlist_id)];
        if (this.wishlist) this.loading_wishlist = false;
        this.desktopViewPort = this.viewPort('lg');
    },
    destroyed() {
        clearTimeout(this.listenerTimeout);
    }
}
</script>
<style scoped>
    .img-product-wishlist {
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid var(--gray-100);
        max-width: 100px;
    }
    .select-public-details .checker {
        height: 0.5rem !important;
    }
    .select-public-details::v-deep label {
        height: 14px;
        width: 26px;
    }
    .select-public-details::v-deep svg {
        width: 13px;
        height: 12px;
    }
    .select-public-details::v-deep .checkbox:checked ~ .checkmark svg {
        transform: translate(-1.0em);
    }
    .btn-disabled {
        cursor: no-drop !important;
        fill: var(--light-gray-501) !important;
    }
    td:first-child {
        width: 50%;
    }
    td {
        padding-block: 1.2rem;
        width: 25%;
    }
    .animation-slide-down {
        animation: slide-down 0.5s;
        transition: var(--transition-1);
    }
    @keyframes slide-down {
        0% {
            transform: translateY(-15%);
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        85% {
            opacity: 1;
        }
        100% {
            transform: translateY(0);
        }
    }
    .wishlist-description {
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 25px;
        transition: var(--transition-1);
    }
    .product-description {
        max-width: 150px;
        font-weight: 500;
    }
    /* .brand-color-container {  } */
</style>
